/** @jsx jsx */
import { jsx, Box, Container, Styled, useThemeUI } from 'theme-ui';
import { shade } from 'polished';
import { useLanguage } from '../utils/getLanguage';

const heroTheme = themeUI => ({
  default: {
    background: { bg: 'white' },
    text: { color: themeUI.colors.text },
    emphasis: {
      fontStyle: 'normal',
      color: 'primary',
    },
  },
  green: {
    background: { bg: themeUI.colors.greenDark },
    text: { color: 'white' },
    emphasis: {
      fontStyle: 'normal',
      color: themeUI.colors.green,
    },
  },
  dark: {
    background: { bg: shade(0.1, themeUI.colors.secondary) },
    text: { color: 'white' },
    emphasis: {
      fontStyle: 'normal',
      color: themeUI.colors.secondary,
    },
  },
});

const PageTitleAndExcerpt = ({ title, excerpt }) => {
  const { theme: themeUI } = useThemeUI();
  const themeCSS = heroTheme(themeUI).default;
  const lang = useLanguage();
  let excerptTitle = false;
  if (excerpt && excerpt.excerpt.substring(0, 1) === '#') excerptTitle = true;

  return (
    <Box lang={lang || 'fi'}>
      {!excerptTitle && (
        <Styled.h1
          sx={{
            mt: 0,
            mb: 4,
            fontSize: 5, // [5, 6, null, 48],
            lineHeight: 'heading',
            letterSpacing: 'heading',
            wordWrap: 'break-word',
            hyphens: 'auto',
            em: { ...themeCSS.emphasis },
            ...themeCSS.text,
          }}
        >
          {title}
        </Styled.h1>
      )}
      {excerpt && (
        <Box
          sx={{
            mt: 0,
            maxWidth: '60ch',
            fontSize: [3, 4],
            ...themeCSS.text,
            h1: {
              fontSize: 5, // [5, 6, null, 48],
              lineHeight: 'heading',
              letterSpacing: 'heading',
              em: {
                fontStyle: 'normal',
                color: themeCSS.emphasis.color,
              },
            },
          }}
          dangerouslySetInnerHTML={{
            __html: excerpt.childMarkdownRemark.html,
          }}
        />
      )}
    </Box>
  );
};

export default PageTitleAndExcerpt;
