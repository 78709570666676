/** @jsx jsx */
import { jsx, Flex, Box, Button, useThemeUI, Container } from 'theme-ui';
import { useState } from 'react';
import Link from './Link';
import { shade } from 'polished';
import { ChevronIcon } from './Icon';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const navTheme = themeUI => ({
  default: {
    box: { bg: 'white' },
    button: { color: themeUI.colors.secondary, ':hover': { color: themeUI.colors.secondary } },
    flex: { borderColor: 'white' },
    link: {
      color: themeUI.colors.secondary,
      ':hover': { color: themeUI.colors.primary },
      '&[aria-current="page"]': {
        color: themeUI.colors.primary,
        boxShadow: ['inset 3px 0 0 ' + themeUI.colors.primary, null, 'inset 0 -3px 0 ' + themeUI.colors.primary],
      },
    },
  },
  green: {
    box: { bg: shade(0.1, themeUI.colors.greenDark) },
    button: { color: 'white', ':hover': { color: 'white' } },
    flex: { borderColor: shade(0.5, themeUI.colors.green) },
    link: {
      color: 'white',
      ':hover': { color: themeUI.colors.green },
      '&[aria-current="page"]': {
        color: themeUI.colors.green,
        boxShadow: ['inset 3px 0 0 ' + themeUI.colors.green, null, 'inset 0 -3px 0 ' + themeUI.colors.green],
      },
    },
  },
  dark: {
    box: { bg: shade(0.1, themeUI.colors.secondary) },
    button: { color: 'white', ':hover': { color: 'white' } },
    flex: { borderColor: shade(0.5, themeUI.colors.secondary) },
    link: {
      color: 'white',
      ':hover': { color: themeUI.colors.primary },
      '&[aria-current="page"]': {
        color: themeUI.colors.blueLight,
        boxShadow: ['inset 3px 0 0 ' + themeUI.colors.blueLight, null, 'inset 0 -3px 0 ' + themeUI.colors.blueLight],
      },
    },
  },
  ['mh.se']: {
    box: { bg: shade(0.1, themeUI.colors.secondary) },
    button: { color: 'white', ':hover': { color: 'white' } },
    flex: { borderColor: shade(0.5, themeUI.colors.secondary) },
    link: {
      color: 'white',
      ':hover': { color: themeUI.colors.newGrayLighter },
      '&[aria-current="page"]': {
        color: themeUI.colors.newGrayLighter,
        boxShadow: [
          'inset 3px 0 0 ' + themeUI.colors.newGrayLighter,
          null,
          'inset 0 -3px 0 ' + themeUI.colors.newGrayLighter,
        ],
      },
    },
  },
  blue: {
    box: { bg: shade(0.1, themeUI.colors.primary) },
    button: { color: 'white', ':hover': { color: 'white' } },
    flex: { borderColor: shade(0.5, themeUI.colors.secondary) },
    link: {
      color: 'white',
      ':hover': { color: themeUI.colors.blueLight },
      '&[aria-current="page"]': {
        color: themeUI.colors.blueLight,
        boxShadow: ['inset 3px 0 0 ' + themeUI.colors.blueLight, null, 'inset 0 -3px 0 ' + themeUI.colors.blueLight],
      },
    },
  },
});

const PageNavigation = ({ navigation, theme, hideWhenNarrow }) => {
  const { theme: themeUI } = useThemeUI();
  const themeCSS = navTheme(themeUI)[theme] || navTheme(themeUI).default;
  const [menu, setMenu] = useState(false);
  const locale = useSelector(state => state.session.locale);
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Box
      sx={{
        display: hideWhenNarrow ? ['none', null, null, 'block'] : 'block',
        ...themeCSS.box,
      }}
    >
      <Button
        variant="plain"
        onClick={() => setMenu(!menu)}
        sx={{
          width: '100%',
          display: ['flex', null, 'none'],
          px: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          ...themeCSS.button,
        }}
      >
        {navigation.headline}
        <ChevronIcon
          sx={{
            width: 16,
            height: 16,
            transform: `rotate(${menu ? '-90deg' : '90deg'})`,
          }}
        />
      </Button>
      <Container>
        <Flex
          sx={{
            display: [menu ? 'flex' : 'none', null, 'flex'],
            width: '100%',
            py: [2, null, 0],
            flexDirection: ['column', null, 'row'],
            borderTop: ['1px solid', null, 'none'],
            ...themeCSS.flex,
            gap: 4,
          }}
        >
          {navigation.links &&
            navigation.links.map(link => (
              <Link
                to={
                  link.page && link.page.parentPage
                    ? `/${link.page.parentPage.slug}/${link.page.slug}`
                    : link.page
                    ? `/${link.page.slug}`
                    : link.url
                }
                key={link.id}
                noTitleFix
                noPathTranslation
                sx={{
                  display: 'block',
                  py: [2, null, 3],
                  fontWeight: 'medium',
                  ...themeCSS.link,
                }}
              >
                {link.title}
              </Link>
            ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default PageNavigation;
