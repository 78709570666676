/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import Link from './Link';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

const BreadcrumbItem = ({ color, children }) => (
  <Box
    sx={{
      fontSize: 1,
      color: color,
      a: {
        color: color,
        ':hover': {
          opacity: 0.8,
        },
      },
      '::after': {
        display: 'inline-block',
        px: 2,
        content: '"/"',
      },
      ':last-child::after': {
        display: 'none',
      },
    }}
  >
    {children}
  </Box>
);

const BreadcrumbLink = ({ to, children }) => (
  <Link to={to} noTitleFix>
    {children}
  </Link>
);

const Breadcrumb = ({ links, parentSlug, color = 'gray', ...props }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Flex {...props}>
      <BreadcrumbItem color={color}>
        <BreadcrumbLink to={parentSlug || '/'}>{translate('frontPage')}</BreadcrumbLink>
      </BreadcrumbItem>

      {links &&
        links.map((link, i) => (
          <BreadcrumbItem key={i} color={color}>
            <BreadcrumbLink to={link.to}>{link.title}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}

      {/**
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </Box>
      */}
    </Flex>
  );
};

export default Breadcrumb;
