/** @jsx jsx */
import { jsx, Box, Container, Grid, Styled, useThemeUI } from 'theme-ui';
import Image from 'gatsby-image';
import { shade } from 'polished';
import { useLanguage } from '../utils/getLanguage';

const heroTheme = themeUI => ({
  default: {
    background: { bg: 'white' },
    text: { color: themeUI.colors.text },
    emphasis: {
      fontStyle: 'normal',
      color: 'primary',
    },
  },
  green: {
    background: { bg: themeUI.colors.greenDark },
    text: { color: 'white' },
    emphasis: {
      fontStyle: 'normal',
      color: themeUI.colors.green,
    },
  },
  dark: {
    background: { bg: shade(0.1, themeUI.colors.secondary) },
    text: { color: 'white' },
    emphasis: {
      fontStyle: 'normal',
      color: themeUI.colors.secondary,
    },
  },
  ['mh.se']: {
    background: { bg: shade(0.1, themeUI.colors.secondary) },
    text: { color: 'white' },
    emphasis: {
      fontStyle: 'normal',
      color: themeUI.colors.secondary,
    },
  },
  blue: {
    background: { bg: shade(0.1, themeUI.colors.primary) },
    text: { color: 'white' },
    emphasis: {
      fontStyle: 'normal',
      color: themeUI.colors.primary,
    },
  },
});

const PageHero = ({ title, excerpt, image, theme = 'default', layout = 'container' }) => {
  const { theme: themeUI } = useThemeUI();
  const themeCSS = heroTheme(themeUI)[theme] || heroTheme(themeUI).default;
  const lang = useLanguage();
  let excerptTitle = false;
  if (excerpt && excerpt.excerpt.substring(0, 1) === '#') excerptTitle = true;

  return (
    <Box
      sx={{
        py: image ? [0, null, null, 4] : 0,
        ...themeCSS.background,
        px: layout === 'text' ? [3, 3, 3, 0] : 0,
      }}
    >
      <Container variant={image ? 'container' : layout}>
        <Grid
          sx={{
            gridTemplateColumns: image ? ['1fr', null, null, '1fr 1fr'] : '1fr',
            gridTemplateRows: image ? 'auto' : '1fr',
            gridGap: [2, null, null, 4],
            alignItems: 'center',
          }}
        >
          <Box
            lang={lang || 'fi'}
            sx={{
              pb: theme !== 'default' ? 0 : [4, null, null, 0],
              order: [1, null, null, 0],
            }}
          >
            {!excerptTitle && (
              <Styled.h1
                sx={{
                  pt: [4, 4, 4, 0],
                  mt: image ? 0 : [3, null, 4, 5],
                  mb: theme === 'default' ? 0 : [3, null, 4, 5],
                  mr: [0, null, null, '72px'],
                  fontSize: [5, 6, null, 48],
                  lineHeight: 'heading',
                  letterSpacing: 'heading',
                  wordWrap: 'break-word',
                  hyphens: 'auto',
                  em: { ...themeCSS.emphasis },
                  ...themeCSS.text,
                }}
              >
                {title}
              </Styled.h1>
            )}
            {excerpt && (
              <Box
                sx={{
                  mt: 24,
                  maxWidth: '60ch',
                  fontSize: [3, 4],
                  ...themeCSS.text,
                  h1: {
                    fontSize: [5, 6, null, 48],
                    lineHeight: 'heading',
                    letterSpacing: 'heading',
                    em: {
                      fontStyle: 'normal',
                      color: themeCSS.emphasis.color,
                    },
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: excerpt.childMarkdownRemark.html,
                }}
              />
            )}
          </Box>
          {image && (
            <Image
              fluid={{ ...image.fluid, aspectRatio: 3 / 2 }}
              sx={{
                order: [0, null, null, 1],
                borderRadius: 2,
                maxHeight: [300, 360, null, 'initial'],
                /*
                bg: theme === 'default' && 'secondary',
                img: {
                  mixBlendMode: theme === 'default' && 'screen',
                }, //*/
              }}
            />
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default PageHero;
